import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import Disbursed from './financeApply/disbursedForm';
import ViewApplication from './financeApply/viewApplication';
import call from '../../service';
import { setDisbursed, setFinanceDetails } from '../../store/actions/action';
import { formatDate_Application, formatDateTime_Application } from '../../utils/dateFormaters';
import CreateFinancePages from './financeApply/createFinancePages.js';
import customStyles from '../dataTablesColumsMap/customTableCss';
import columns from '../dataTablesColumsMap/loanContractListColumns';
import DataTable from 'react-data-table-component';
import Footer from '../partial/footer';
import Feedback from 'react-bootstrap/esm/Feedback';
const queryString = require('query-string');


const Finance = ({ userTokenDetails, setDisbursed, disbursed, setFinanceDetails, financeDetails, navToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [filterData, setFilterData] = useState({})
  const [financeLogs, setFinanceLogs] = useState({ modal: false, data: {} });
  const [kycAction, setkycAction] = useState(false);
  const [dashData, setdashData] = useState({});
  const [bankData, setBankData] = useState({});
  const [application, setApplication] = useState({
    flag: false,
    id: null,
    type: null
  });

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    fetchTableData(1, { userId, type_id: userTypeId });
    //------------------------------------------------------------------
  }, [financeDetails.status === false, disbursed.modal === false, filterData]);

  useEffect(() => {
    checkFilterQuery()

    call('get', 'getDashboardData')
      .then((result) => {
        console.log("fetched result in dashboard===>", result)
        setdashData(result);
        setkycAction(true);
      })
      .catch((err) => {
        console.log("error:", err)
      })

    call('POST', 'getBankersList', {})
      .then((result) => {
        console.log("result in getBankersList===>", result)
        setBankData(result);
      })
      .catch((err) => {
        console.log("error:", err)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // datatable
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [completeButton, setcompleteButton] = useState(0)
  const [refresh, setrefresh] = useState(0);

  const [count, setCount] = useState(0)
  const [searchKey, setsearchKey] = useState("")
  const [filterCount, setFilterCount] = useState({})

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getLoans', objectAPI).then((result) => {
      console.log('running getLoans api-->', result);
      settableData(formatDataFortable(result.data))
      setTotalRows(result.count)
      setCount(result.count)
      setFilterCount(result.filterCount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getLoans', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getLoans', objectAPI).then((result) => {
      // console.log('running getLoans api-->', result);
      settableData(formatDataFortable(result.data))
      setTotalRows(result.count)
      setCount(result.count)
      setFilterCount(result.filterCount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getLoans', e);
      setLoading(false)
    })
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  function checkFilterQuery() {
    let query = queryString.parse(window.location.search);
    filterStateButtons(query.status / 1)
    setcompleteButton(query.status / 1)
  }

  const filterStateButtons = (type) => {
    if (type === 1) {
      setFilterData({
        ...filterData,
        onlyPending: true,
        onlyApproved: false,
        onlyDisbursed: false,
        onlyDeclined: false
      })
    } else if (type === 2) {
      setFilterData({
        ...filterData,
        onlyPending: false,
        onlyApproved: true,
        onlyDisbursed: false,
        onlyDeclined: false
      })
    } else if (type === 3) {
      setFilterData({
        ...filterData,
        onlyPending: false,
        onlyApproved: false,
        onlyDisbursed: true,
        onlyDeclined: false
      })
    } else if (type === 4) {
      setFilterData({
        ...filterData,
        onlyPending: false,
        onlyApproved: false,
        onlyDisbursed: false,
        onlyDeclined: true
      })
    }
  };

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const reset = () => {
    setcompleteButton(0)
    setFilterData({})
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setsearchKey("")
    setrefresh(refresh + 1)
  }


  function getType(type) {
    let rtype;
    switch (type / 1) {
      case 1:
        rtype = 'PO';
        break;
      case 2:
        rtype = 'LC';
        break;
      case 3:
        rtype = 'INVOICE';
        break;
      case 4:
      case 5:
        rtype = 'MY TRADE';
        break;
      case 6:
        rtype = 'FREIGHT';
        break;
      default:
        rtype = 'FIN';
    }

    return rtype
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };
  //---------------------------------------------------------------------------------------------------------------------
  // Body Component

  function ExportTableCSV() {

    let objectAPI = {
      "currentPage": 1,
      "resultPerPage": totalRows,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getLoans', objectAPI).then((result) => {
      console.log('running getLoans api-->', result);

      const dbResult = result.data
      let csvString = "Application No.,Type,Supplier,Buyer,Lender,Requested Amount,Disbused Amount,Interest Rate,Credit Days,Request Date,Disbused Date,Status,Buyer's Status\r\n"

      for (let i = 0; i < dbResult.length; i++) {
        let rowString = `${dbResult[i].reference_no},
      ${dbResult[i].type == 1 ? 'PO' : dbResult[i].type == 2 ? 'LC' : 'INVOICE'},
      ${dbResult[i].suppliername ? dbResult[i].suppliername : "NA"},
      ${dbResult[i].buyername ? dbResult[i].buyername : "NA"},
      ${dbResult[i].lendername ? dbResult[i].lendername : "NA"},
      ${dbResult[i].amount ? dbResult[i].amount : "NA"},
      ${dbResult[i].issued_amount ? dbResult[i].issued_amount : "NA"},
      ${dbResult[i].interest_rate ? dbResult[i].interest_rate : "NA"},
      ${dbResult[i].credit_days ? dbResult[i].credit_days : "NA"},
      ${dbResult[i].created_at ? formatDate_Application(dbResult[i].created_at) : "NA"},
      ${dbResult[i].issue_date ? formatDate_Application(dbResult[i].issue_date) : "NA"},
      ${dbResult[i].status ? dbResult[i].status : "NA"},
      ${dbResult[i].buyer_status ? (dbResult[i].buyer_status == "1" ? 'Accepted' : dbResult[i].buyer_status == "0" ? "Rejected" : "NA") : 'NA'}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `Fintech_Finance_${userEmail.split('@')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getLoans', e);
    })
  }

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      // console.log("dataArray[i]===>", dataArray[i])
      let tempObj = {}

      // tempObj.appNo = "FINAPP:" + (dataArray[i].id ? dataArray[i].id : '--');
      tempObj.invoice_due_date = dataArray[i].due_date
      tempObj.disbused_amount = dataArray[i].disbused_amount
      tempObj.reference_no = dataArray[i].reference_no ? dataArray[i].reference_no : 'NA';
      tempObj.noa_signedBy_buyer = dataArray[i].noa_signedBy_buyer
      tempObj.repaid_status = dataArray[i].repaid_status
      tempObj.type = dataArray[i].type ? getType(dataArray[i].type) : 'NA';
      tempObj.suppliername = dataArray[i].suppliername ? dataArray[i].suppliername : 'NA';
      tempObj.buyername = dataArray[i].buyername ? dataArray[i].buyername : 'NA';
      tempObj.contract_amount = dataArray[i].contract_amount ? dataArray[i].contract_amount.toFixed(2) : 'NA';
      tempObj.amount = dataArray[i].amount ? dataArray[i].amount.toFixed(2) : 'NA';
      tempObj.issued_amount = dataArray[i].issued_amount ? dataArray[i].issued_amount : 'NA';
      tempObj.issue_date = dataArray[i].issue_date ? formatDate_Application(dataArray[i].issue_date) : 'NA';
      tempObj.credit_days = dataArray[i].credit_days ? dataArray[i].credit_days : 'NA';
      tempObj.interest_rate = dataArray[i].interest_rate ? dataArray[i].interest_rate : '--';
      tempObj.lendername = dataArray[i].lendername ? dataArray[i].lendername : 'NA';
      tempObj.created_at = dataArray[i].created_at ? formatDate_Application(dataArray[i].created_at) : 'NA';
      tempObj.status = dataArray[i].status ? dataArray[i].status : 'NA';
      tempObj.buyer_status = dataArray[i].buyer_status ? dataArray[i].buyer_status : 'NA';
      tempObj.seller_status = dataArray[i].seller_status ? dataArray[i].seller_status : 'NA';
      tempObj.lender_status = dataArray[i].lender_status ? dataArray[i].lender_status : 'NA';
      tempObj.id = dataArray[i].id ? dataArray[i].id : 'NA'
      tempObj.typeId = dataArray[i].type ? dataArray[i].type : 'NA'
      tempObj.action = (<>
        {(aclMap.finance_access && aclMap.finance_access.edit_finance) &&
          dataArray[i].status !== 4 &&
          <button className="btn btn-info btn-sm mr-2 ml-2" onClick={() => {
            (dataArray[i].seller_status / 1 === 2 && dataArray[i].lender_status / 1 === 2) ?
              setFinanceDetails(true, dataArray[i].type / 1, 2, { action: "viewFinance", FinanceID: dataArray[i].id, sellerStatus: dataArray[i].seller_status / 1, lenderStatus: dataArray[i].lender_status / 1 }) :
              setFinanceDetails(true, dataArray[i].type / 1, 1, { action: "viewFinance", FinanceID: dataArray[i].id, sellerStatus: dataArray[i].seller_status / 1, lenderStatus: dataArray[i].lender_status / 1 })
          }}>
            {(dataArray[i].seller_status / 1 === 2 && dataArray[i].lender_status / 1 === 2) ?
              <i class="fa fa-eye" aria-hidden="true"></i> :
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>}
          </button>}

        {(aclMap.finance_access && aclMap.finance_access.disburse_finance) &&
          dataArray[i].seller_status === 2 && dataArray[i].lender_status === 2 && dataArray[i].status !== 4 &&
          <button className="btn btn-info btn-sm mr-2 ml-2" onClick={() => setDisbursed(true, { id: dataArray[i].id, type: dataArray[i].type })}>
            <i className="fa fa-credit-card-alt" aria-hidden="true"></i>
          </button>}

        {dataArray[i].status === 4 &&
          <button type="button" className="btn btn-info btn-sm mr-2 ml-2" onClick={() => setApplication({ flag: true, id: dataArray[i].id, type: dataArray[i].type })} >
            <i class="fas fa-angle-double-down" aria-hidden="true"></i>
          </button>}

        <button type="button" className="btn btn-info btn-sm mr-2 ml-2" onClick={() => setFinanceLogs({ modal: true, data: dataArray[i] })}>
          <i class="fas fa-search" aria-hidden="true"></i>
        </button>

      </>)

      resultArray.push(tempObj)
    }
    return resultArray
  }

  const TableExpandedComponent = (props) => {
    let { data } = props
    // console.log("data props==>", data);
    return (
      <>
        <div className="col-md-12 row pt-2 text-center pb-0 mb-0">
          <div className="ml-5 mr-5 border-left">
            <p className="pl-2 pt-1" >Contract Amount: {data.contract_amount}</p>
          </div>
          <div className="ml-5 mr-5 border-left">
            <p className="pl-2 pt-1" >{data.status === 4 ? "Issued Amount: " : "Requested Amount: "} {
              data.amount
            }</p>

          </div>
          <div className="border-left">
            <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 ">
              <span className="pr-2"><i class="fa fa-line-chart" aria-hidden="true"></i></span>Insurance Quote</button>
          </div>
          <div className="ml-5 mr-5 border-left">
            <p className="pl-2 pt-1" >Interest Rate: {data.interest_rate}%</p>

          </div>
          <div className="ml-5 mr-5 border-left">
            <p className="pl-2 pt-1" >Credit Days: {data.credit_days}</p>

          </div>
          {data.type === "INVOICE" || data.type === "LC" || data.type === "PO" || data.type === "FREIGHT" ? (
            <div className="border-left">
              <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 "
                onClick={() => {
                  console.log("data to nav", true, data.typeId / 1, 2, { action: "viewFinance", FinanceID: data.id, sellerStatus: data.seller_status / 1, lenderStatus: data.lender_status / 1 });
                  (data.seller_status / 1 === 2 && data.lender_status / 1 === 2) ?
                    setFinanceDetails(true, data.typeId / 1, 2, { action: "viewFinance", tab: 1, FinanceID: data.id, sellerStatus: data.seller_status / 1, lenderStatus: data.lender_status / 1 }) :
                    setFinanceDetails(true, data.typeId / 1, 1, { action: "viewFinance", tab: 1, FinanceID: data.id, sellerStatus: data.seller_status / 1, lenderStatus: data.lender_status / 1 })
                }}>
                <span className="pr-2">
                  <i class="fa fa-file" aria-hidden="true"></i>
                </span>Documents</button>
            </div>
          ) : null}
          {data.noa_signedBy_buyer / 1 == 1 ? (
            <div className="mx-2 border-left">
              <p className="pl-2 pt-1 text-success font-weight-bold" >Buyer has signed NOA</p>
            </div>
          ) : null}
          {data.repaid_status / 1 == 1 ? (
            <div className="border-left">
              <p className="pl-2 pt-1 text-success font-weight-bold" >Buyer has Repaid</p>
            </div>
          ) : null}
        </div>
      </>
    )
  };

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar state={'finance'} userTokenDetails={userTokenDetails} />

          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            {financeDetails.status ?
              <CreateFinancePages
                userTokenDetails={userTokenDetails} />
              :
              (disbursed.modal) ?
                <Disbursed
                  userTokenDetails={userTokenDetails} /> :
                (application.flag) ?
                  <ViewApplication
                    application={application}
                    setApplication={setApplication}
                    userTokenDetails={userTokenDetails}
                  />
                  : <>
                    <Header
                      title={'Finance Contracts'}
                      total={'Total Finance Transactions ' + (filterCount.total || 0)}
                      userTokenDetails={userTokenDetails}
                      createFinance={{ flag: true }}
                    />

                    <div class="d-md-flex mt-3 mb-3">
                      <div class="filter-bg">
                        <ul>
                          <li className={"pilot-cont  cursor-pointer " + (completeButton === 1 ? "shadow" : '')} onClick={() => { setcompleteButton(1); filterStateButtons(1); }}>
                            <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Pending (${filterCount.pending || 0})`}</li>
                          <li className={"progress-cont  cursor-pointer " + (completeButton === 2 ? "shadow" : '')} onClick={() => { setcompleteButton(2); filterStateButtons(2); }}>
                            <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Approved (${filterCount.approved || 0})`}</li>
                          <li className={"complete-cont  cursor-pointer " + (completeButton === 3 ? "shadow" : '')} onClick={() => { setcompleteButton(3); filterStateButtons(3); }}>
                            <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Disbursed (${filterCount.disbursed || 0})`}</li>
                          <li className={"danger-cont text-danger  cursor-pointer " + (completeButton === 4 ? "shadow" : '')} onClick={() => { setcompleteButton(4); filterStateButtons(4); }}>
                            <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Declined (${filterCount.declined || 0})`}</li>
                        </ul>
                      </div>
                      <div class="ml-md-auto">
                        <ul class="range-slider">
                          <li>
                            <input name="search" id="searchInput" placeholder="Search" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} onKeyDown={handleKeyPress} />
                            <button type="button" onClick={() => {
                              setFilterData({
                                ...filterData,
                                "search": searchKey
                              })
                            }}><i class="fas fa-search fa-lg"></i></button>
                          </li>

                          <li>
                            <label>From</label>
                            <input type="date" id="searchFromdate" name="fromDate" onChange={filterState} />
                          </li>
                          <li>
                            <label>To</label>
                            <input type="date" id="searchTodate" name="toDate" onChange={filterState} />
                          </li>
                          {userTypeId == 1 &&
                            <li>
                              {/* <label>Lender List</label> */}
                              <select className="form-control" name="lenderType" value={filterData.lenderType} onChange={(event) => { setFilterData({ lenderType: event.target.value }) }}>
                                <option value="" selected>--Select Lender--</option>
                                {bankData && bankData.length > 0 && bankData.map((bankList) => {
                                  return (<option value={bankList.tbl_user_id + ':' + bankList.company_name}>{bankList.company_name}</option>)
                                })}
                              </select>
                            </li>
                          }
                          <li>
                            <input type="button" value="Reset" class="btn btn-primary btn-sm" onClick={() => reset()} />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <DataTable
                        columns={columns}
                        data={tableData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                        persistTableHead
                        expandableRows
                        expandableRowsComponent={<TableExpandedComponent />}
                        expandOnRowClicked
                        highlightOnHover
                        onSort={handleSort}
                        sortServer
                        striped
                        noHeader
                        customStyles={customStyles}
                      />
                    </div>
                    <hr />
                    <div className="float-left col-md-6 ">
                      <div className="col-md-12 row">
                        <div className="col-md-6">
                          <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                            <i class="fas fa-file-csv"></i> Export CSV
                          </button>
                        </div>
                      </div>
                    </div>
                  </>}

            {kycAction.modal &&
              (dashData && dashData.userDetails) &&
              (dashData.userDetails.company_pan_verification !== "true" || dashData.userDetails.company_gst_verification !== "true") &&
              <div className={"modal show"} id="Confirmation">
                <div className="modal-dialog modal-lg border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Verification</h4>
                      <a href={'/dashboard'}>
                        <button type="button" className="close" >×</button>
                      </a>
                    </div>
                    <div className="modal-body">

                      <div className="col-md-12 text-center">
                        <h3> KYC Verification Alert </h3>
                      </div>
                      <hr className="pt-1 pb-1" />
                      <div className="col-md-12">
                        <div className="row">

                          <div className="col-md-12 text-center">
                            <label className="form-check-label text-danger">{`Hey "${dashData.userDetails.company_name}" Your KYC is not verified. Please complete your KYC process.`}</label>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="modal-footer primary">
                      <a href={'/editprofile'}>
                        <button className="btn btn-info btn-sm">Go to KYC Verification</button>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            }
            {financeLogs.modal &&
              <div className={"modal" + (financeLogs.modal ? " show" : "")} id="changelogsModal">
                <div className="modal-dialog modal-xl border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Finance logs</h4>
                      <button type="button" className="close" onClick={() => setFinanceLogs({ modal: false, data: {} })}>×</button>
                    </div>
                    <div className="modal-body calc-inner-modal">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="main-timeline2">

                              <div className="timeline">
                                <span className="icon fa fa-globe" />
                                <a href="javascript:void(0);" className="timeline-content">
                                  <h3 className="title">Request Raised</h3>
                                  <p className="description">On {formatDateTime_Application(financeLogs.data.created_at ? financeLogs.data.created_at : 'NA')}</p>
                                </a>
                              </div>

                              <div className="timeline">
                                <span className="icon fa fa-globe" />
                                <a href="javascript:void(0);" className="timeline-content">
                                  <h3 className="title">Approved by User</h3>
                                  <p className="description">On {(financeLogs.data.user_approved_at ? formatDateTime_Application(financeLogs.data.user_approved_at) : 'NA')}</p>
                                </a>
                              </div>

                              <div className="timeline">
                                <span className="icon fa fa-globe" />
                                <a href="javascript:void(0);" className="timeline-content">
                                  <h3 className="title">Exhibit Agreement</h3>
                                  <p className="description">On {(financeLogs.data.exhibit_signed_at ? formatDateTime_Application(financeLogs.data.exhibit_signed_at) : 'NA')}</p>
                                </a>
                              </div>

                              <div className="timeline">
                                <span className="icon fa fa-globe" />
                                <a href="javascript:void(0);" className="timeline-content">
                                  <h3 className="title">Notice of Assignment</h3>
                                  <p className="description">On {(financeLogs.data.noa_signed_at ? formatDateTime_Application(financeLogs.data.noa_signed_at) : 'NA')}</p>
                                </a>
                              </div>

                              <div className="timeline">
                                <span className="icon fa fa-globe" />
                                <a href="javascript:void(0);" className="timeline-content">
                                  <h3 className="title">Amount Disbursal</h3>
                                  <p className="description">On {(financeLogs.data.disbursed_at ? formatDateTime_Application(financeLogs.data.disbursed_at) : 'NA')}</p>
                                </a>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}

            {kycAction &&
              (dashData && dashData.userDetails) &&
              (dashData.userDetails.company_gst_verification !== "true" || dashData.userDetails.company_iec_verification !== "true") &&
              <div className={"modal show"} id="Confirmation">
                <div className="modal-dialog modal-lg border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Verification</h4>
                      <a href={'/dashboard'}>
                        <button type="button" className="close" >×</button>
                      </a>
                    </div>
                    <div className="modal-body">

                      <div className="col-md-12 text-center">
                        <h3> KYC Verification Alert </h3>
                      </div>
                      <hr className="pt-1 pb-1" />
                      <div className="col-md-12">
                        <div className="row">

                          <div className="col-md-12 text-center">
                            <label className="form-check-label text-danger">{`Hey "${dashData.userDetails.company_name}" Your KYC is not verified. Please complete your KYC process.`}</label>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="modal-footer primary">
                      <a href={'/editprofile'}>
                        <button className="btn btn-info btn-sm">Go to KYC Verification</button>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            }
          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    disbursed: state.disbursed,
    financeDetails: state.financeDetails,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setDisbursed: (flag, data) => { dispatch(setDisbursed({ modal: flag, info: data })) },
    setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Finance)
