const columns = [
  {
    name: 'Date of Establishment',
    selector: 'dateofestablishment',
    sortable: true,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Channel Partner Name',
    selector: 'cp_contact_person',
    sortable: false,
    grow: 3
  },
  {
    name: 'Channel Partner Email',
    selector: 'cp_email_id',
    sortable: false,
    grow: 3
  },
  {
    name: 'Channel Partner Contact',
    selector: 'cp_contact_no',
    sortable: false,
    grow: 3
  },
  {
    name: 'Lead By',
    selector: 'lead_by',
    sortable: false,
  },
  {
    name: 'Supplier Name',
    selector: 'suppliername',
    sortable: false,
  },
  {
    name: 'Supplier Industry Type',
    selector: 'industryType',
    sortable: false,
  },
  {
    name: 'Buyer Name',
    selector: 'buyername',
    sortable: false,
  },
  {
    name: 'DUNS No',
    selector: 'buyerDUNS',
    sortable: false,
  },
  {
    name: 'Buyers Country',
    selector: 'buyerCountry',
    sortable: false,
  },
  {
    name: 'Buyers Country Name',
    selector: 'buyerCountryName',
    sortable: false,
  },
  {
    name: 'Terms of Payment',
    selector: 'buyerTermsOfPayment',
    sortable: false,
  },
  {
    name: 'Product Details',
    selector: 'buyerProductDetails',
    sortable: false,
  },
  {
    name: 'Previous Year Sale',
    selector: 'buyerPreviousYearSale',
    sortable: false,
  },
  {
    name: 'Expected Sale',
    selector: 'buyerExpectedSale',
    sortable: false,
  },
  {
    name: 'Stenn',
    selector: 'stennLimit',
    sortable: false,
  },
  {
    name: 'Remark',
    selector: 'stennRemarks',
    sortable: false,
    grow: 3
  },
  {
    name: 'Modifi',
    selector: 'modifiLimit',
    sortable: false,
  },
  {
    name: 'Remark',
    selector: 'modifiRemarks',
    sortable: false,
    grow: 3
  },
  {
    name: 'Tradewind',
    selector: 'tradewindLimit',
    sortable: false,
  },
  {
    name: 'Remark',
    selector: 'tradewindRemarks',
    sortable: false,
    grow: 3
  },
  {
    name: 'Created At',
    selector: 'created_at',
    sortable: false,
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: false,
  }
];


export const columns2 = [
  {
    name: 'Date of Establishment',
    selector: 'dateofestablishment',
    sortable: true,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  // {
  //   name: 'Lead By',
  //   selector: 'lead_by',
  //   sortable: false,
  // },
  {
    name: 'Seller Id',
    selector: 'selleridentifier',
    sortable: false,
    grow: 6
  },
  {
    name: 'Buyer Id',
    selector: 'buyeridentifier',
    sortable: false,
    grow: 7
  },
  {
    name: 'Supplier Name',
    selector: 'suppliername',
    sortable: false,
    grow: 4
  },
  {
    name: 'Supplier Industry Type',
    selector: 'industryType',
    sortable: false,
    grow: 4
  },
  {
    name: 'Buyer Name',
    selector: 'buyername',
    sortable: false,
    grow: 4
  },
  {
    name: 'DUNS No',
    selector: 'buyerDUNS',
    sortable: false,
  },
  {
    name: 'Buyers Country Code',
    selector: 'buyerCountry',
    sortable: false,
  },
  {
    name: 'Buyers Country Name',
    selector: 'buyerCountryName',
    sortable: false,
  },
  {
    name: 'Terms of Payment',
    selector: 'buyerTermsOfPayment',
    sortable: false,
    grow: 5
  },
  {
    name: 'Inco Terms',
    selector: 'incoTerms',
    sortable: false
  },
  {
    name: 'Product Details',
    selector: 'buyerProductDetails',
    sortable: false,
    grow: 5
  },
  {
    name: 'Buyer Currency',
    selector: 'buyerCurrency',
    sortable: false,
  },
  {
    name: 'Previous Year Sale',
    selector: 'buyerPreviousYearSale',
    sortable: false,
  },
  {
    name: 'Expected Sale',
    selector: 'buyerExpectedSale',
    sortable: false,
  },
  {
    name: 'Previous Invoice Doc',
    selector: 'prevInvDoc',
    sortable: false
  },
  {
    name: 'Previous PO Doc',
    selector: 'prevPODoc',
    sortable: false
  },
  // {
  //   name: 'Stenn',
  //   selector: 'stennLimit',
  //   sortable: false,
  // },
  // {
  //   name: 'Remark',
  //   selector: 'stennRemarks',
  //   sortable: false,
  //   grow: 3
  // },
  // {
  //   name: 'Modifi',
  //   selector: 'modifiLimit',
  //   sortable: false,
  // },
  // {
  //   name: 'Remark',
  //   selector: 'modifiRemarks',
  //   sortable: false,
  //   grow: 3
  // },
  // {
  //   name: 'Tradewind',
  //   selector: 'tradewindLimit',
  //   sortable: false,
  // },
  // {
  //   name: 'Remark',
  //   selector: 'tradewindRemarks',
  //   sortable: false,
  //   grow: 3
  // },
  {
    name: 'Created At',
    selector: 'created_at',
    sortable: false,
    grow: 4
  },
  {
    name: 'Assigned Credit',
    selector: 'assignedCredit',
    sortable: false,
    grow: 4
  },
  {
    name: 'Assigned Remark',
    selector: 'assignedRemark',
    sortable: false,
    grow: 4
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: false,
  }
];



export default columns