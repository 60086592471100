import React, { useState, useEffect, useRef } from "react";
import Logo from "../assets/images/logo_1.png";
import RefImg from "../assets/images/ref_img.jpg";
import { ToastContainer } from "react-toastify";
// import SelectField from "../common-components/SelectField";
import axios from "axios";
import BgImg from '../assets/images/sign_up_bg.png'
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import { platformBackendUrl } from "../../urlConstants";

const onboardingTabs = [
  { name: "Work Field" },
  { name: "Company Type" },
  { name: "KYC details" },
  { name: "Company details" },
  { name: "Personal details" },
  { name: "Create password", last: true }
]

const FieldTypes = [
  {
    name: 'Import/Export', type: "finTech", typeId: 19, techType: 2,
    soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true, foreign: true
  },
  {
    name: 'Banks/Finance/NBFC', type: 'finTech', typeId: 8, techType: 2,
    soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true, foreign: true
  },
  {
    name: 'Channel Partner', type: "CP", typeId: 20, techType: null,
    individual: true, soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true
  },
  {
    name: 'Franchise Partner', type: "FP", typeId: 20, techType: null,
    individual: true, soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true
  }
]

const companyTypes = [
  { name: 'Individual', alt: "individual", aadharDocument: true, panDocument: true },
  { name: 'Sole Proprietorship', alt: "soleProprietorship", gstDocument: true, iecDocument: true, iecDocumentIgnore1: true, iecDocumentIgnore2: true, iecDocumentIgnore3: true },
  { name: 'Partnership', alt: "partnership", gstDocument: true, iecDocument: true, iecDocumentIgnore1: true, iecDocumentIgnore2: true, iecDocumentIgnore3: true },
  { name: 'PVT/PUB. LTD', alt: 'pvtPubLtd', gstDocument: true, iecDocument: true, cinDocument: true, iecDocumentIgnore1: true, iecDocumentIgnore2: true, iecDocumentIgnore3: true },
  { name: 'LLP', alt: "llp", gstDocument: true, iecDocument: true, iecDocumentIgnore1: true, iecDocumentIgnore2: true, iecDocumentIgnore3: true },
  { name: 'Foreign Entity/INC', alt: "foreign" }
]

const kycDocTypes = [
  { name: 'CIN Number', alt: "cinDocument" },
  { name: 'GST Number', alt: "gstDocument" },
  { name: 'IEC Number', alt: "iecDocument" },
  { name: 'PAN Number', alt: "panDocument" },
  { name: 'Aadhar Number', alt: "aadharDocument" }
]

const cpDocTypes = [
  { name: 'PAN Number' },
  { name: 'Aadhar Number' }
]

const formTitles = [
  { name: "Select your field" },
  { name: "Select your company type" },
  { name: "Enter your registration details" },
  { name: "Enter your company details" },
  { name: "Enter your personal details" },
  { name: "Create password" }
]

const industryData = [
  { value: "apparelTextile", name: "Apparels & Textile" },
  { value: "industrialMech", name: "Industrial & Mechanical" },
  { value: "foodBeverage", name: "Food & Beverage" },
  { value: "electronicConsumer", name: "Electronics & Consumer Goods" },
  { value: "eCommerce", name: "E-Commerce" },
  { value: "gamingMedia", name: "Gaming & Media" },
  { value: "fmcg", name: "FMCG" },
  { value: "medicalPharma", name: "Medical & Pharmaceutical" },
  { value: "auto", name: "Auto" },
  { value: "frozenFood", name: "Frozen Foods" },
  { value: "ITStaffing", name: "IT Staffing" },
  { value: "argo", name: "AGRO" },
  { value: "govtEntity", name: "Buyer/Supplier Government Entity" },
  { value: "oilGas", name: "Oil/Gas" },
  { value: "diamond", name: "Diamond" },
  { value: "preciousStone", name: "Precious Stone" },
  { value: "gold", name: "Gold" },
  { value: "others", name: "Others" },
];

const NewRegistration = () => {
  const refOtp1 = useRef(null);
  const refOtp2 = useRef(null);
  const refOtp3 = useRef(null);
  const refOtp4 = useRef(null);

  const [stepperProgress, updateStepperProgress] = useState(0);
  const [data, setData] = useState({ nameTitle: "Mr.", phoneCode: "91", tcAccept: false })
  const [errors, setErrors] = useState({})
  const [countryData, setcountryData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showReferralPopup, toggleReferralPopup] = useState(false);
  const [isEmailVerified, toggleIsEmailVerified] = useState(false);
  const [isMobVerified, toggleIsMobVerified] = useState(false);
  const [otpPopup, setOtpPopup] = useState({ show: false, type: "" });

  const astrix = <span className="required-field text-danger">*</span>;

  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setcountryData(result.data.message);
      }
    });
  }, []);

  const handleFieldChange = (index, type) => {
    setData({ ...data, [type]: index })
  }

  const handleStepperProgress = (type) => {
    type === "inc" ? updateStepperProgress(stepperProgress + 1) : updateStepperProgress(stepperProgress - 1)
  }

  const handleNext = () => {
    let err = {}
    if (stepperProgress === 0 && !FieldTypes[data.workField]) {
      err["msg"] = "Select work field"
    }
    if (stepperProgress === 1 && !companyTypes[data.organizationType]) {
      err["msg"] = "Select company type"
    }
    if (stepperProgress === 2) {
      let validateFields = ["gstDocument", "iecDocument", "cinDocument", "aadharDocument", "panDocument"]
      for (let index = 0; index < validateFields.length; index++) {
        const element = validateFields[index];
        if (companyTypes[data.organizationType][element] &&
          !companyTypes[data.organizationType][element + "Ignore" + data.workField]) {
          if (!data[element]) {
            err[element] = "Mandatory field"
          }
        }
      }
    }
    if (stepperProgress === 3) {
      let validateFields = ["companyName", "industryType", "country", "companyAddress", "companyCity", "companyPostalCode"]
      for (let index = 0; index < validateFields.length; index++) {
        const element = validateFields[index];
        if (!data[element]) {
          err[element] = "Mandatory field"
        }
      }
    }
    if (stepperProgress === 4) {
      let validateFields = ["contactPerson", "email", "contactNo"]
      if (!isEmailVerified) {
        err["email"] = "Kindly verify email id to proceed"
      }
      if (!isMobVerified) {
        err["contactNo"] = "Kindly verify mobile number to proceed"
      }
      for (let index = 0; index < validateFields.length; index++) {
        const element = validateFields[index];
        if (!data[element]) {
          err[element] = "Mandatory field"
        }
      }
    }
    if (stepperProgress === 5) {
      let validateFields = ["password", "repassword"]
      if (data.password && data.password.length < 6) {
        err["password"] = "Password should be atleast 6 characters long"
      }
      else if (data.password != data.repassword) {
        err["repassword"] = "Password mismatch"
      }
      for (let index = 0; index < validateFields.length; index++) {
        const element = validateFields[index];
        if (!data[element]) {
          err[element] = "Mandatory field"
        }
      }
    }
    if (!Object.keys(err).length) {
      if (stepperProgress == 5) {
        onRegister()
      }
      else if (stepperProgress == 2) {
        let req = {
          "organizationType": companyTypes[data.organizationType]["alt"],
          "cinDocumentName": data["cinDocument"],
          "gstDocumentName": data["gstDocument"],
          "iecDocumentName": data["iecDocument"]
        }
        if (req.organizationType != "foreign" && req.organizationType != "individual") {
          setShowLoader(true)
          call('POST', 'getKYCDetail', req).then((result) => {
            // console.log("getKYCDetail==>", result);
            if (result.company_name != '') {
              setData({
                ...data,
                "companyName": result.company_name,
                "contactPerson": result.company_name,
                "companyAddress": result.company_address,
                "email": result.company_email,
                "contactNo": result.company_mobile,
                "gst_company_detail": result.gst_company_detail ? result.gst_company_detail : null,
                "iec_company_detail": result.iec_company_detail ? result.iec_company_detail : null,
                "cin_company_detail": result.cin_company_detail ? result.cin_company_detail : null,
                "type": "finTech"
              });
              toastDisplay("KYC verified successfully", "success");
              handleStepperProgress("inc")
            }
            else {
              toastDisplay("Your KYC is Not-Verified", "error");
            }
            setShowLoader(false)
          }).catch((e) => {
            setShowLoader(false)
            toastDisplay("Something went wrong", "error");
          })
        }
        else {
          handleStepperProgress("inc")
        }
      }
      else {
        handleStepperProgress("inc")
      }
    }
    else {
      toastDisplay(err["msg"] || "Form validation error", "error")
    }
    setErrors(err)
  }

  function handleChange(e) {
    e.persist()
    if (e.target.name.includes('otp')) {
      let currentIndex = e.target.name.split("p")[1] / 1
      if (e.target.value) {
        if (currentIndex == 1) {
          refOtp2.current.focus()
        }
        if (currentIndex == 2) {
          refOtp3.current.focus()
        }
        if (currentIndex == 3) {
          refOtp4.current.focus()
        }
      }
      else {
        if (currentIndex == 4) {
          refOtp3.current.focus()
        }
        if (currentIndex == 3) {
          refOtp2.current.focus()
        }
        if (currentIndex == 2) {
          refOtp1.current.focus()
        }
      }
    }
    setData({ ...data, [e.target.name]: e.target.value })
    setErrors({ ...errors, [e.target.name]: "" })
  }


  const onRegister = () => {
    let reqObject = {
      "typeId": FieldTypes[data.workField]["typeId"],
      "cinDocumentName": data.cinDocument || null,
      "gstDocumentName": data.gstDocument || null,
      "iecDocumentName": data.iecDocument || null,
      "panDocumentName": data.panDocument || null,
      "aadharDocumentName": data.aadharDocument || null,
      "iecDocumentName": data.iecDocument || null,
      "panDocumentName": data.panDocument || null,
      "aadharDocumentName": data.aadharDocument || null,
      "organizationType": companyTypes[data.organizationType]["alt"],
      "companyName": data.companyName,
      "contactPerson": data.contactPerson,
      "companyAddress": data.companyAddress,
      "email": data.email,
      "contactNo": data.contactNo,
      "gst_company_detail": data.gst_company_detail || null,
      "iec_company_detail": data.iec_company_detail || null,
      "cin_company_detail": data.cin_company_detail || null,
      "type": FieldTypes[data.workField]["type"],
      "referralCode": data.referalCode,
      "password": data.password,
      "passwordConfirm": data.repassword,
      "termsAndCondition": true,
      "country": data.country,
      "industryType": data.industryType,
      "techType": FieldTypes[data.workField]["techType"],
      "companyCity": data.companyCity,
      "companyPostalCode": data.companyPostalCode,
      "phoneCode": data.phoneCode,
      "nameTitle": data.nameTitle
    }
    if (FieldTypes[data.workField]["typeId"] === 20) {
      reqObject["adminProfile"] = false
      reqObject["finTechType"] = true
      reqObject["role"] = FieldTypes[data.workField]["type"]
      reqObject["status"] = 1
      if (data.country != "IN") {
        reqObject["setKycTrue"] = true
      }
      setShowLoader(true)
      call('POST', 'registration', reqObject).then((result) => {
        setShowLoader(false)
        toastDisplay("Operation success, contact admin for next process", "success")
        setTimeout(() => {
          window.location = 'login'
        }, 1500);
      }).catch(err => {
        setShowLoader(false)
        toastDisplay("Something went wrong", "error");
      })
    }
    else {
      let formData = new FormData()
      Object.keys(reqObject).forEach(item => {
        formData.append(item, reqObject[item])
      })
      setShowLoader(true)
      call('POST', 'registration', formData).then((result) => {
        setShowLoader(false)
        toastDisplay("User registered successfully", "success")
        setTimeout(() => {
          window.location = 'login'
        }, 1500);
      }).catch(err => {
        setShowLoader(false)
        toastDisplay(err.message || "Something went wrong", "error");
      })
    }
  }

  function toolTip(msg) {
    return (
      <span className="error-icon" flow="right" tooltip={msg}>i</span>
    )
  }

  async function validateReferralCode() {
    if (!data["referalCode"]) {
      return setErrors({ ...errors, "referalCode": "Enter Referral Code" })
    }
    setShowLoader(true)
    call("POST", 'verifyrefercode', { "referCode": data.referalCode }).then(result => {
      setShowLoader(false)
      if (result.length) {
        toggleReferralPopup(false)
      }
      else {
        setData({ ...data, "referalCode": "" })
        setErrors({ ...errors, "referalCode": "Invalid Referal Code" })
      }
    }).catch(err => {
      setShowLoader(false)
      setData({ ...data, "referalCode": "" })
      setErrors({ ...errors, "referalCode": "Something went wrong" })
    })
  }

  async function sendOtp(type) {
    if (type === "email" && !data.email) {
      return null
    }
    if (type === "mob" && !data.contactNo) {
      return null
    }
    setShowLoader(true)
    call("POST", 'sendVerifyOtp', type === "email" ? { sendOnEmail: true, email: data.email } :
      { sendOnMob: true, contactNo: "+" + data.phoneCode + "" + data.contactNo }).then(result => {
        setShowLoader(false)
        if (result) {
          toastDisplay(result, "success")
          setOtpPopup({ show: true, type })
        }
      }).catch(err => {
        setShowLoader(false)
        toastDisplay(err, 'error')
      })
  }

  async function verifyOtp(type) {
    setShowLoader(true)
    let req = {
      otp: data.otp1 + "" + data.otp2 + "" + data.otp3 + "" + data.otp4
    }
    call("POST", 'sendVerifyOtp', type === "email" ? { ...req, verifyEmail: true, email: data.email } :
      { ...req, verifyMob: true, contactNo: "+" + data.phoneCode + "" + data.contactNo }).then(result => {
        setShowLoader(false)
        if (result) {
          toastDisplay(result, "success")
          setOtpPopup({ show: false, type: "" })
          setData({ ...data, otp1: "", otp2: "", otp3: "", otp4: "" })
          setErrors({})
          if (type === "email") {
            toggleIsEmailVerified(true)
          }
          else {
            toggleIsMobVerified(true)
          }
        }
      }).catch(err => {
        setShowLoader(false)
        toastDisplay(err, 'error')
      })
  }

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {showReferralPopup ? (
        <div className="modal show" id="">
          <div className="modal-dialog modal-m border-inner" >
            <div className="modal-content px-4 mx-4 py-2">
              <div className="modal-header border-0">
                <button type="button" className="close" onClick={() => toggleReferralPopup(false)}>×</button>
              </div>
              <h6 className="modal-title text-dark text-center">Enter who referred you</h6>
              <img
                className="cursor my-4" src={RefImg} alt="logo" height="250rem" width="100%"
              />
              <div className="form-floating mb-4 w-100 py-2 position-relative">
                <input
                  type="text"
                  className={`form-control ${errors["referalCode"] ? "border-danger" : ""}`}
                  name={"referalCode"}
                  value={data["referalCode"]}
                  onChange={handleChange}
                  placeholder={"Enter Referral Code"}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      validateReferralCode()
                    }
                  }}
                />
                {errors["referalCode"] ? (
                  <span className="text-danger fontValid">{errors["referalCode"]}</span>
                ) : (
                  ""
                )}
                <i
                  style={{
                    "left": "85%",
                    "top": "0.9rem"
                  }}
                  onClick={validateReferralCode}
                  className="position-absolute cursor
                fas fa-2x fa-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      ) : null
      }
      {otpPopup.show ? (
        <div className="modal show" id="">
          <div className="modal-dialog modal-m border-inner" >
            <div className="modal-content px-4 mx-4 py-2">
              <div className="modal-header border-0">
                <button type="button" className="close" onClick={() => setOtpPopup({ show: false, type: "" })}>×</button>
              </div>
              <h6 className="modal-title text-dark text-center">{`Verify ${otpPopup.type === "mob" ? "Mobile Number" : "Email Id"}`}</h6>
              <div className="py-4 justify-content-center d-flex flex-column align-items-center">
                <>
                  <label className="cursor w-90" >Enter OTP</label>
                  <div className="form-group mb-4 w-90 d-flex flex-row justify-content-between"
                    style={{ height: "3rem" }}
                  >
                    <input type="text" id="otp1" ref={refOtp1} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp1" value={data.otp1} onChange={handleChange} />
                    <input type="text" id="otp2" ref={refOtp2} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp2" value={data.otp2} onChange={handleChange} />
                    <input type="text" id="otp3" ref={refOtp3} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp3" value={data.otp3} onChange={handleChange} />
                    <input type="text" id="otp4" ref={refOtp4} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp4" value={data.otp4} onChange={handleChange} />
                  </div>
                  {errors.otp && <label className="w-90 text-danger">{errors.otp}</label>}
                  <label className="cursor w-90 light-font" >{`An OTP has been sent on your mentioned 
                  ${otpPopup.type === "mob" ? "Mobile Number" : "Email Id"}`}</label>
                  <div className="row w-100 p-4">
                    <label
                      onClick={() => sendOtp(otpPopup.type)}
                      className="cursor w-50 text-color1" >Resend OTP</label>
                    <label
                      onClick={() => setOtpPopup({ show: false, type: "" })}
                      className="cursor w-50 text-color1" >{`Change ${otpPopup.type === "mob" ? "Mobile Number" : "Email Id"}`}</label>
                  </div>
                  <button type="button"
                    onClick={() => verifyOtp(otpPopup.type)}
                    class={`new-btn py-2 w-50 mb-4 text-white`}>
                    {"Verify OTP"}
                  </button>
                </>
              </div>
            </div>
          </div>
        </div>

      ) : null}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <div
        style={{
          backgroundImage: `url(${BgImg})`,
        }}
        className="row bg-img">
        <div className="w-100 align-items-center signup-div d-none">
          <div className="col-6">
            <h5 style={{}} className="font-size-30 font-wt-600 my-4 text-center">Sign up</h5>
          </div>
          <div className="col-6 text-center">
            <img onClick={() => { window.location = "/" }}
              style={{}}
              className="cursor" src={Logo} alt="logo" height="60px"
            />
          </div>
        </div>
        <div className="col-md-4 justify-content-center d-flex mb-4 pb-4" style={{}}>
          <div className="d-flex flex-column stepper-div ">
            <h5 style={{}} className="font-size-30 font-wt-600 my-4 py-4 text-center signup-h">Sign up</h5>
            {onboardingTabs.map((item, index) => {
              return (
                <>
                  <div style={{
                    "border": `1px solid ${index <= stepperProgress ? "#5CB8D3" : "#BDBDBD"}`,
                    "background": index <= stepperProgress ? "#5CB8D3" : "#BDBDBD",
                    "border-radius": "100%", "height": '1.8rem', "width": '1.8rem'
                  }}>
                    <label className="stepper-label font-size-16 font-wt-400"
                    >{item.name}</label>
                  </div>

                  <div className={`${item.last ? "d-none" : "" + "stepper-bar"}`} style={{
                    "border": `1px solid ${index < stepperProgress ? "#5CB8D3" : "#BDBDBD"}`,
                    "background": index < stepperProgress ? "#5CB8D3" : "#BDBDBD"
                  }} />
                </>
              )
            })}
          </div>
        </div>

        <div className="d-flex flex-column col-md-6 align-items-center" style={{}}>
          <div className="my-4 py-4 text-center signup-h">
            <img onClick={() => { window.location = "/" }}
              style={{}}
              className="cursor" src={Logo} alt="logo" height="60px"
            />
          </div>
          <h5 className="pb-4 font-size-18 font-wt-500"><span><i
            onClick={() => handleStepperProgress('dec')}
            class={`fa fa-arrow-left mx-4 ${stepperProgress === 0 && 'd-none'}`} aria-hidden="true"></i></span>{formTitles[stepperProgress]["name"]}</h5>
          {
            stepperProgress == 0 && (
              <>
                {FieldTypes.map((item, index) => (
                  <div
                    onClick={() => handleFieldChange(index, "workField")} className={`new-btn py-2 w-50 mb-4 ${data.workField === index ? "text-white" : 'text-dark bg-white'}`}>
                    {item.name}
                  </div>
                ))}</>
            )
          }
          {
            stepperProgress == 1 && (
              <>
                {companyTypes.map((item, index) => {
                  if (FieldTypes[data.workField][item.alt]) {
                    return (
                      <div
                        onClick={() => handleFieldChange(index, "organizationType")}
                        className={`new-btn py-2 w-50 mb-4 ${data.organizationType === index ? "text-white" : 'text-dark bg-white'}`}>
                        {item.name}
                      </div>
                    )
                  }
                })}</>
            )
          }

          {
            stepperProgress == 2 && (
              <>
                {kycDocTypes.map((item, index) => (
                  <div className="form-floating mb-4 w-50 position-relative">
                    <input
                      type="text"
                      className={`form-control ${errors[item.alt] ? "border-danger" : ""}`}
                      id={item.alt}
                      name={item.alt}
                      value={data[item.alt]}
                      onChange={handleChange}
                      placeholder={item.name + (companyTypes[data.organizationType][item.alt]
                        && !companyTypes[data.organizationType][item.alt + "Ignore" + data.workField] ? ' *' : "")}
                    />
                    {errors[item.alt] && (
                      toolTip(errors[item.alt])
                    )}
                    {/* <label htmlFor="floatingInput">{item.name}
                      {companyTypes[data.organizationType][item.alt] ? astrix : ""}
                    </label> */}
                  </div>
                ))}

              </>
            )
          }
          {stepperProgress == 3 && (
            <>
              <div className="form-floating mb-4 w-50 position-relative">
                <input
                  type="text"
                  className={`form-control ${errors["companyName"] ? "border-danger mb-0" : ""}`}
                  name={"companyName"}
                  value={data["companyName"]}
                  onChange={handleChange}
                  placeholder={"Company Name *"}
                />
                {/* <label htmlFor="floatingInput">{"Company Name"}
                  {astrix}
                </label> */}
                {/* {errors["companyName"] ? (
                  <span className="text-danger fontValid">{errors["companyName"]}</span>
                ) : (
                  ""
                )} */}
                {errors["companyName"] && (
                  toolTip(errors["companyName"])
                )}
              </div>
              <div className="form-floating mb-4 w-50 position-relative">
                {/* <SelectField
                  Label={`Industry Type`}
                  AriaLabel="industryType"
                  Id="industryType"
                  Name="industryType"
                  Value={data.industryType}
                  OnChange={handleChange}
                  Astrix={astrix}
                  ValidError={errors.industryType}
                >
                  <option value="">Select Industry Type</option>
                  {industryData.length
                    ? industryData.map((item) => (
                      <option value={item.value} key={item}>
                        {item.name}
                      </option>
                    ))
                    : null}
                </SelectField> */}
                <select
                  className={" form-control" + (!errors.industryType ? '' : ' border-danger')} name="industryType" value={data.industryType} onChange={handleChange} >
                  <option value="">Select Industry Type</option>
                  {industryData.length
                    ? industryData.map((item) => (
                      <option value={item.value} key={item}>
                        {item.name}
                      </option>
                    ))
                    : null}
                </select>
                {/* {errors["industryType"] ? (
                  <span className="text-danger fontValid">{errors["industryType"]}</span>
                ) : (
                  ""
                )} */}
                {errors["industryType"] && (
                  toolTip(errors["industryType"])
                )}
              </div>
              <div className="form-floating mb-4 w-50 position-relative">
                {/* <SelectField
                  Label="Country "
                  AriaLabel="country"
                  Id="country"
                  Name="country"
                  Value={data.country}
                  OnChange={handleChange}
                  Astrix={astrix}
                  ValidError={errors.country}
                >
                  <option value="">Select Country</option>
                  {countryData.length
                    ? countryData.map((item) => (
                      <option value={item.sortname} key={item}>
                        {item.name}
                      </option>
                    ))
                    : null}
                </SelectField> */}
                <select
                  className={" form-control" + (!errors.country ? '' : ' border-danger')} name="country" value={data.country} onChange={handleChange} >
                  <option value="">Select Country</option>
                  {countryData.length
                    ? countryData.map((item) => (
                      <option value={item.sortname} key={item}>
                        {item.name}
                      </option>
                    ))
                    : null}
                </select>
                {/* {errors["country"] ? (
                  <span className="text-danger fontValid">{errors["country"]}</span>
                ) : (
                  ""
                )} */}
                {errors["country"] && (
                  toolTip(errors["country"])
                )}
              </div>
              <div className="form-floating mb-4 w-50 position-relative">
                <input
                  type="text"
                  className={`form-control ${errors["companyAddress"] ? "border-danger" : ""}`}
                  name={"companyAddress"}
                  value={data["companyAddress"]}
                  onChange={handleChange}
                  placeholder={"Company Address *"}
                />
                {/* <label htmlFor="floatingInput">{"Company Address"}
                  {astrix}
                </label> */}
                {/* {errors["companyAddress"] ? (
                  <span className="text-danger fontValid">{errors["companyAddress"]}</span>
                ) : (
                  ""
                )} */}
                {errors["companyAddress"] && (
                  toolTip(errors["companyAddress"])
                )}
              </div>
              <div className="form-floating mb-4 w-50 position-relative">
                <input
                  type="text"
                  className={`form-control ${errors["companyCity"] ? "border-danger" : ""}`}
                  name={"companyCity"}
                  value={data["companyCity"]}
                  onChange={handleChange}
                  placeholder={"Company City *"}
                />
                {/* {errors["companyCity"] ? (
                  <span className="text-danger fontValid">{errors["companyCity"]}</span>
                ) : (
                  ""
                )} */}
                {errors["companyCity"] && (
                  toolTip(errors["companyCity"])
                )}
              </div>
              <div className="form-floating mb-4 w-50 position-relative">
                <input
                  type="text"
                  className={`form-control ${errors["companyPostalCode"] ? "border-danger" : ""}`}
                  name={"companyPostalCode"}
                  value={data["companyPostalCode"]}
                  onChange={handleChange}
                  placeholder={"Company Postal Code *"}
                />
                {/* {errors["companyPostalCode"] ? (
                  <span className="text-danger fontValid">{errors["companyPostalCode"]}</span>
                ) : (
                  ""
                )} */}
                {errors["companyPostalCode"] && (
                  toolTip(errors["companyPostalCode"])
                )}
              </div>
            </>
          )}

          {stepperProgress == 4 && (
            <>
              <div className="form-floating mb-4 row w-50 position-relative">
                <select className={"form-control w-25" + (errors.nameTitle ? " border-danger" : "")}
                  name="nameTitle" value={data.nameTitle} onChange={handleChange}>
                  <option value={'Mr'}>{'Mr'}</option>
                  <option value={'Miss'}>{'Miss'}</option>
                </select>
                <input
                  type="text"
                  className={`form-control w-75 ${errors["contactPerson"] ? "border-danger" : ""}`}
                  name={"contactPerson"}
                  value={data["contactPerson"]}
                  onChange={handleChange}
                  placeholder={"Full Name *"}
                />
                {/* <label htmlFor="floatingInput">{"Full Name "}{astrix}
                </label> */}
                {/* {errors["contactPerson"] ? (
                  <span className="text-danger fontValid">{errors["contactPerson"]}</span>
                ) : (
                  ""
                )} */}
                {errors["contactPerson"] && (
                  toolTip(errors["contactPerson"])
                )}
              </div>
              <div className="form-floating mb-4 w-50 position-relative">
                <input
                  disabled={isEmailVerified}
                  type="email"
                  className={`form-control ${errors["email"] ? "border-danger" : ""}`}
                  name={"email"}
                  value={data["email"]}
                  onChange={handleChange}
                  placeholder={"Email Id *"}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      sendOtp("email")
                    }
                  }}
                />
                {errors["email"] && (
                  toolTip(errors["email"])
                )}
                {data.email ? (
                  <i
                    style={{
                      "left": "90%",
                      "top": "0.4rem"
                    }}
                    onClick={() => sendOtp("email")}
                    className={`position-absolute cursor fas fa-2x ${isEmailVerified ? "fa-check" : "fa-arrow-right"} ${isEmailVerified ? "text-success" : ""}`}></i>
                ) : null}
              </div>
              <div className="form-floating mb-4 row w-50 position-relative">
                <select
                  disabled={isMobVerified} className={"form-control w-25" + (errors.phoneCode ? " border-danger" : "")}
                  name="phoneCode" value={data.phoneCode} onChange={handleChange}>
                  {countryData.length ? countryData.map((item, index) => (
                    <option value={item.phonecode}>{`+${item.phonecode}`}</option>
                  ))
                    : ""}
                </select>
                <input
                  disabled={isMobVerified}
                  type="number"
                  className={`form-control w-75 ${errors["contactNo"] ? "border-danger" : ""}`}
                  name={"contactNo"}
                  value={data["contactNo"]}
                  onChange={handleChange}
                  placeholder={"Mobile Number *"}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      sendOtp("mob")
                    }
                  }}
                />
                {errors["contactNo"] && (
                  toolTip(errors["contactNo"])
                )}
                {data.contactNo ? (
                  <i
                    style={{
                      "left": "90%",
                      "top": "0.4rem"
                    }}
                    onClick={() => sendOtp("mob")}
                    className={`position-absolute cursor fas fa-2x ${isMobVerified ? "fa-check" : "fa-arrow-right"} ${isMobVerified ? "text-success" : ""}`}></i>
                ) : null}
              </div>
              {/* <div className="form-floating mb-4 w-50">
                <input
                  type="text"
                  className={`form-control ${errors["referalCode"] ? "border-danger" : ""}`}
                  name={"referalCode"}
                  value={data["referalCode"]}
                  onChange={handleChange}
                  placeholder={"User Referral Code (If Available)"}
                />
                {errors["referalCode"] ? (
                  <span className="text-danger fontValid">{errors["referalCode"]}</span>
                ) : (
                  ""
                )}
              </div> */}
            </>
          )}

          {stepperProgress == 5 && (
            <>
              <div className="form-floating mb-4 w-50 position-relative">
                <input
                  type="password"
                  className={`form-control ${errors["password"] ? "border-danger" : ""}`}
                  name={"password"}
                  value={data["password"]}
                  onChange={handleChange}
                  placeholder={"Enter password *"}
                />
                {/* <label htmlFor="floatingInput">{"Enter password "}{astrix}
                </label> */}
                {/* {errors["password"] ? (
                  <span className="text-danger fontValid">{errors["password"]}</span>
                ) : (
                  ""
                )} */}
                {errors["password"] && (
                  toolTip(errors["password"])
                )}
              </div>

              <div className="form-floating mb-4 w-50 position-relative">
                <input
                  type="password"
                  className={`form-control ${errors["repassword"] ? "border-danger" : ""}`}
                  name={"repassword"}
                  value={data["repassword"]}
                  onChange={handleChange}
                  placeholder={"Re-enter password *"}
                />
                {/* <label htmlFor="floatingInput">{"Re-enter password "}{astrix}
                </label> */}
                {/* {errors["repassword"] ? (
                  <span className="text-danger fontValid">{errors["repassword"]}</span>
                ) : (
                  ""
                )} */}
                {errors["repassword"] && (
                  toolTip(errors["repassword"])
                )}
              </div>
            </>
          )}

          {stepperProgress === 5 ? (
            <>
              <div class="form-check mb-4 w-50">
                <label className={`font-wt-400 mb-4 cursor ${data.referalCode ? 'text-color1' : ""}`}
                  onClick={() => toggleReferralPopup(true)}
                >{data.referalCode ? "Refferal Code Applied" : "I have a referral code"}
                  {!data.referalCode ? (
                    <i class="fas fa fa-arrow-right mx-1"></i>) : " !"}
                </label>
                <label class="form-check-label text-center" for="tcAccept">
                  <input class="form-check-input new-check-box" type="checkbox" name="tcAccept" id="tcAccept"
                    onChange={handleChange}
                    checked={data.tcAccept}
                  />
                  I Agree to the
                  <span
                    onClick={() => { window.open("Privacy_&_Cookie_Policy.pdf", "_blank") }}
                    className="text-primary cursor"> Terms & Conditions</span> and <span
                      onClick={() => { window.open("Privacy_&_Cookie_Policy.pdf", "_blank") }}
                      className="text-primary cursor">Privacy policy</span> of Trade reboot fin</label>
              </div>
              <button type="button"
                disabled={!data.tcAccept}
                onClick={handleNext}
                class={`new-btn py-2 w-50 mb-4 text-white`}>
                {"Register"}
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={handleNext}
              className={`new-btn py-2 w-50 mb-4 text-white`}>{"Continue"}</button>
          )}

          <label className="font-wt-400 font-size-14" >Already registered? <span
            onClick={() => { window.location = 'login' }}
            className="text-primary cursor">Login</span></label>

        </div>
      </div>
    </>
  )

}

export default NewRegistration;