import { getUserDataFromCookie } from '../../utils/cookieHelper';

var userDetails = getUserDataFromCookie();
// const aclMap = userDetails.aclMap ? JSON.parse(userDetails.aclMap) : {}
let userTypeId = userDetails.type_id ? userDetails.type_id : null

var columns = [
  {
    name: 'Application No.',
    selector: 'reference_no',
    sortable: true,
    grow: 2,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: false,
  },
  {
    name: 'Supplier',
    selector: 'suppliername',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Buyer',
    selector: 'buyername',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Lender',
    selector: 'lendername',
    grow: 2,
    sortable: true,
  },
  {
    name: 'Requested Amount',
    selector: 'amount',
    sortable: false,
  },
  {
    name: 'Disbursed Amount',
    selector: 'issued_amount',
    sortable: false,
  },
  {
    name: 'Interest Rate',
    selector: 'interest_rate',
    sortable: false,
  },
  {
    name: 'Credit Days',
    selector: 'credit_days',
    sortable: false,
  },
  {
    name: 'Request Date',
    selector: 'created_at',
    sortable: false,
    grow: 2
  },
  {
    name: 'Invoice Due Date',
    selector: 'invoice_due_date',
    sortable: false,
    grow: 2
  },
  // {
  //   name: 'Disbursed Date',
  //   selector: 'issue_date',
  //   sortable: false,
  // },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
    cell: row => (row.status === "1" && row.seller_status === 1 && row.lender_status === 1 && userTypeId != 8) ? "Applied" :
      (row.status === "1" && row.seller_status === 1 && row.lender_status === 1 && userTypeId === 8) ? "In-Progress" :
        (row.status === "3" && row.seller_status === 1 && row.lender_status === 1 && userTypeId === 8) ? "Offer Sent" :
          (row.status === "3" && row.seller_status === 1 && row.lender_status === 1 && userTypeId != 8) ? "Offer Received" :
            (row.status !== "4" && row.seller_status === 1 && row.lender_status === 2) ? "Approved By Lender" :
              (row.status !== "4" && row.seller_status === 2 && row.lender_status === 1) ? "Approved By Seller" :
                (row.status !== "4" && row.status !== "5" && row.seller_status === 2 && row.lender_status === 2) ? "Approved" :
                  (row.status === "4") ? "Disbursed" :
                    (row.status === "5") ? "Declined" :
                      (row.status === "New") ? "New, Deal received by Stenn" :
                        (row.status === "Approving") ? "Approving" :
                          (row.status === "Requesting") ? "Requesting additional Documents" :
                            (row.status === "LimitsReserved") ? "LimitsReserved" :
                              (row.status === "DocsSentForSign") ? "DocsSentForSign" :
                                (row.status === "DocsReceived") ? "DocsReceived" :
                                  (row.status === "Approved") ? "Approved, Ready to Finance" :
                                    (row.status === "Financed") ? "Supplier Financed" :
                                      (row.status === "Declined") ? "Declined" : "",
    conditionalCellStyles: [

      {
        when: row => (row.status !== "4" && row.seller_status === 1 && row.lender_status === 1),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status !== "4" && row.seller_status === 1 && row.lender_status === 2),
        style: {
          backgroundColor: 'rgba(9, 164, 170, 1)',  //orange
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status !== "4" && row.seller_status === 2 && row.lender_status === 1),
        style: {
          backgroundColor: 'rgba(9, 164, 170, 1)',  //green
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status !== "4" && row.seller_status === 2 && row.lender_status === 2),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status === "4"),
        style: {
          backgroundColor: 'rgba(212, 222, 54)',  //green
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status === "5"),
        style: {
          backgroundColor: 'rgba(248, 0, 0, 0.9)',  //red
          color: 'white',
        },
      },
      {
        when: row => (row.status === "New"),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status === 'Approving'),
        style: {
          backgroundColor: 'rgba(9, 164, 170, 1)',  //orange
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status === 'Requesting'),
        style: {
          backgroundColor: 'rgba(60, 165, 58, 0.9)',  //light green
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status === 'LimitsReserved'),
        style: {
          backgroundColor: 'rgba(60, 165, 58, 0.9)',  //light green
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status === 'DocsSentForSign'),
        style: {
          backgroundColor: 'rgba(60, 165, 58, 0.9)',  //light green
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status === 'DocsReceived'),
        style: {
          backgroundColor: 'rgba(60, 165, 58, 0.9)',  //light green
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status === 'Approved'),
        style: {
          backgroundColor: 'rgba(60, 165, 58, 0.9)',  //light green
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status === 'Financed'),
        style: {
          backgroundColor: 'rgba(60, 165, 58, 0.9)',  //light green
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status === 'Declined'),
        style: {
          backgroundColor: 'rgba(248, 0, 0, 0.9)',  //red
          color: 'white',
        },
      },
    ]
  },
  // {
  //   name: "Buyer's Status",
  //   selector: 'buyer_status',
  //   sortable: false,
  //   cell: row => (row.buyer_status === '1') ? "Accepted" :
  //     (row.buyer_status === '0') ? "Rejected" : "NA",
  //   conditionalCellStyles: [
  //     {
  //       when: row => (row.buyer_status === "1"),
  //       style: {
  //         backgroundColor: 'rgba(60, 165, 58, 0.9)',  //light green
  //         color: 'rgba(71, 71, 71, 1)',
  //       },
  //     },
  //     {
  //       when: row => (row.buyer_status == "0"),
  //       style: {
  //         backgroundColor: 'rgba(248, 0, 0, 0.9)',  //red
  //         color: 'white',
  //       },
  //     },
  //   ]
  // },
  {
    name: "Action",
    grow: 3,
    selector: 'action',
    center: true
  }
];


export default columns;
